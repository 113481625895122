import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { getMenuBar } from "../actions/menubar.action";
import { map, tap } from "rxjs/operators";
import { AppConstantsService } from "../../constants/app-constants.service";
import { CommanservicesService } from "../../services/commanservices.service";



// State Model
export class getMenuBarStateModel {
    getMenuBarList: any;
    menubarListLoaded: boolean
}

@State<getMenuBarStateModel>({
    name: 'getMenuBarList',
    defaults: {
        getMenuBarList: [],
        menubarListLoaded: false
    }
})



@Injectable()
export class MenuBarSate {

    data: any;
    dummyObj: any = {};
    public URL: any;
    public tab: any;
  public userUniqueId

    constructor(private appConstants: AppConstantsService,
        public dataService: CommanservicesService,
        public route: ActivatedRoute,
        public common: CommanservicesService,
        public constant: AppConstantsService) {
    }

    // selecter has logic to select data
    @Selector()
    static getMenubarList(state: getMenuBarStateModel) {
        return state.getMenuBarList
    }

    // get loaded brand
    @Selector()
    static getMenubarLoaded(state: getMenuBarStateModel) {
        return state.menubarListLoaded;
    }

    @Action(getMenuBar)
    getMenuBar({ getState, setState }: StateContext<getMenuBarStateModel>) {
        return this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['MENU_BAR_LIST']+this.userUniqueId).pipe(tap(result => {
    
            this.data = result;
            const state = getState();
            setState({
                ...state,
                getMenuBarList: this.data.result,
                menubarListLoaded: true
            });
        }));
    }




}