 <app-new-header></app-new-header>
 <!-- <app-header></app-header> -->
<div  *ngIf="!showErrorPage" class="mbVPadBtm">
    <router-outlet  (activate)="onActivate()">
        <div style="position: relative;" >
            <div id="success-snackbar" class="animated  fadeInDown"></div>
            <div id="error-snackbar" class="animated  fadeInDown"></div>
            <div id="warning-snackbar" class="animated  fadeInDown"></div>
        </div>
    </router-outlet>
</div>
<!--<app-footer  *ngIf="!showErrorPage" class="desktopView"></app-footer>-->
<div class="desktopView">
<app-new-footer></app-new-footer>
</div>
<div class="mobile_footer mobileView" >
    <mobile-footer></mobile-footer>
</div>


<under-maintenance *ngIf="showErrorPage"></under-maintenance>

