<!-- !--------HEADER FOR MOBILE VIEW-------->
<section id="mobileHeader" class="mobileView">
    <div class="container-fluid">
        <div class="row mobileFixedHeader primaryGradient">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mobileHeadPadT" (click)="back()">
                <div class="headerWithBackArrow">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    <span>{{item.displayName}}</span>
                </div>
            </div>
        </div>
    </div>
</section>
<a id="backToTopbuttonDetails"></a>
<section>
    <div class="detail-page" *ngIf="!isLoading">
        <div class="container-fluid page-margin mt-2">
            <span class="breadCum desktopView cursorPointer">
                <b routerLink=""><i class="fa fa-home secondThemeColor" aria-hidden="true"></i> Home</b>
                <span *ngIf="item.itemName">&nbsp;<b class="fa fa-angle-right secondThemeColor" aria-hidden="true"></b>
                </span>

            </span>
            <section class="content">
                <article class="demo-area" (mouseenter)="a()">
                    <div class="desktopView">
                        <div class="row  mb-4 three-cards">

                            <div class="col-12 col-sm-12 col-md-12-col-lg-6 col-xl-4 col-xxl-4 mt-3">
                                <div class=" card-one">
                                    <div class="card-margin image-card-height">
                                        <div class="text-center zoom-image">
                                            <img class="main-image-height" [ngClass]="{'outofstock-img': item.isInventory && !item.isStockAvailable}"
                                                src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-300,h-300,cm-pad_resize/{{item.imageFilePath}}"
                                                [attr.data-zoom]="dataService.appConstant.IMAGE_URL + item.imageFilePath + '?w=1000&ch=DPR&dpr=2'"
                                                loading="lazy" alt="Image"
                                                onError="this.src='assets/img/errorLogo.png'">
                                                <div class="out-of-stock-message" *ngIf="item.isInventory && !item.isStockAvailable">
                                                    Out of Stock
                                            </div>
                                            <!-- <img src="assets\IMAGE_HOME\Protinex.png" class="main-image-height"> -->
                                        </div>
                                        <div *ngIf="item.itemImageBoList && item.itemImageBoList.length <= 3"
                                            class="d-flex justify-content-center mt-3">
                                            <div *ngFor="let img of item.itemImageBoList;let i=index"
                                                class="badge rounded-4"
                                                [ngClass]="{'rounded-3': i == currentimageIndex}">
                                                <div class="image-height">
                                                    <div class="inner-span">
                                                        <img src="{{dataService.appConstant.IMAGE_URL}}{{img.filePath}}"
                                                            loading="lazy" onError="this.src='assets/img/errorLogo.png'"
                                                            (click)="openImg(i)" class="review-image">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="img-small-wrap"
                                            *ngIf="item.itemImageBoList && item.itemImageBoList.length > 3">
                                            <section id="productDetailIMage-slider">
                                                <owl-carousel-o [options]="advertisingSlider1">
                                                    <ng-container *ngFor="let img of item.itemImageBoList;let i=index">
                                                        <ng-template carouselSlide>
                                                            <div class="SliderImage"
                                                                [ngClass]="{'outofstock-img': item.isInventory && item.stockInHand<=0}">
                                                                <div class="d-flex justify-content-center mt-2">
                                                                    <div class="badge rounded-4"
                                                                        [ngClass]="{'rounded-3': i == currentimageIndex}">
                                                                        <div class="image-height">
                                                                            <div class="inner-span">
                                                                                <img src="{{dataService.appConstant.IMAGE_URL}}{{img.filePath}}"
                                                                                    loading="lazy"
                                                                                    style="position: inherit"
                                                                                    onError="this.src='assets/img/errorLogo.png'"
                                                                                    (click)="openImg(i)"
                                                                                    class="review-image">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </ng-container>
                                                </owl-carousel-o>
                                            </section>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="detail  col-12 col-sm-12 col-md-12-col-lg-6 col-xl-5 col-xxl-5 mt-3">
                                <div class="cardscroll">
                                    <div class="card-one mb-2">
                                        <div class="card-margin">
                                            <div class="row px-2">
                                                <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-9 col-xxl-9">
                                                    <span *ngIf="item.brandImage">
                                                        <a>
                                                            <img class="brandImagecss"
                                                                src="{{dataService.appConstant.IMAGE_URL}}{{item.brandImage}}"
                                                                alt="Divine Nutrition Limited Edition logo"></a>
                                                    </span>
                                                    <!-- <span>
                                            |Accessories
                                        </span> -->
                                                </div>
                                                <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-3 col-xxl-3">
                                                    <span *ngIf="reviewRatingobject.ratingCount"
                                                        class="for-rettings p-1 f-13">
                                                        {{reviewRatingobject.ratingCount}} Ratings

                                                    </span>
                                                </div>
                                            </div>
                                            <div class="mt-2"><strong class="f-21"> {{item.itemName}}</strong></div>
                                            <p class="text-color"
                                                *ngIf="item && item.itemVarientBoList && item.itemVarientBoList?.length > 0">
                                                {{item.itemVarientBoList[0].attributeValue}} - {{item.brandName}}
                                            </p>
                                            <hr class="mx-2">
                                            <!-- <p *ngIf="item.shortDesc">{{item.shortDesc}}</p> -->
                                            <!-- <p *ngIf="!item.shortDesc">NA</p> -->
                                            <p *ngIf="item.longDesc">{{item.longDesc}}</p>
                                        </div>

                                    </div>
                                    <div class="card-one mt-2">
                                        <div class="card-margin card-height-review">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8 col-xxl-8">
                                                    <div class="d-flex">
                                                        <strong class="f-18">Reviews</strong>
                                                        <div class="text-right"
                                                            *ngIf="reviewRatingobject && reviewRatingobject.avgRating > 0">
                                                            <!-- <span class="reviewRating">{{reviewRatingobject.avgRating}} <i
                                                        class="fa fa-star changeStartClr" aria-hidden="true"></i></span> -->
                                                            <span class="mx-1 f-13 text-secondary">
                                                                ({{ratingCount}}Review )
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class=" boder-card mt-2"
                                                *ngIf="!item.isReviewed && item.isPurchaseItem">
                                                <div class="p-4">
                                                    <span *ngIf="!item.isReviewed && item.isPurchaseItem && !reviewForm"
                                                        class="d-flex"><i class="fa fa-edit"></i>
                                                        <h6 class="fw-bold cursorPointer mx-1" (click)="writeReview()">
                                                            Write an
                                                            Review</h6>
                                                    </span>
                                                    <div>
                                                        <form name="WriteReviewform" #WriteReviewform="ngForm"
                                                            (ngSubmit)="WriteReviewform.valid && saveReviewforms(WriteReviewform)">
                                                            <div *ngIf="reviewForm">
                                                                <div class="">
                                                                    <ul class="rate-area">
                                                                        <input type="radio" id="5-star" name="crating"
                                                                            [(ngModel)]="reviewFormData.rating"
                                                                            value="5">
                                                                        <label for="5-star" title="Amazing">5
                                                                            stars</label>
                                                                        <input type="radio" id="4-star" name="crating"
                                                                            [(ngModel)]="reviewFormData.rating"
                                                                            value="4">
                                                                        <label for="4-star" title="Good">4
                                                                            stars</label>
                                                                        <input type="radio" id="3-star" name="crating"
                                                                            [(ngModel)]="reviewFormData.rating"
                                                                            value="3">
                                                                        <label for="3-star" title="Average">3
                                                                            stars</label>
                                                                        <input type="radio" id="2-star" name="crating"
                                                                            [(ngModel)]="reviewFormData.rating"
                                                                            value="2">
                                                                        <label for="2-star" title="Not Good">2
                                                                            stars</label>
                                                                        <input type="radio" id="1-star"
                                                                            [(ngModel)]="reviewFormData.rating"
                                                                            name="crating" value="1"
                                                                            aria-required="true">
                                                                        <label for="1-star" title="Bad">1
                                                                            star</label>
                                                                    </ul>
                                                                </div>

                                                                <input type="text" placeholder="write title name"
                                                                    class="form-control bg-white boder-card mt-2"
                                                                    #reviewTitle="ngModel"
                                                                    [(ngModel)]="reviewFormData.reviewTitle"
                                                                    name="reviewTitle" required>
                                                                <div *ngIf="reviewTitle.valid || reviewTitle.touched || WriteReviewform.submitted"
                                                                    class="text-danger">
                                                                    <div *ngIf="reviewTitle.errors && reviewTitle.errors.required"
                                                                        class="text-danger">
                                                                        Title must be required
                                                                    </div>
                                                                </div>
                                                                <textarea type="text" placeholder="write your review"
                                                                    class="form-control bg-white boder-card mt-1"
                                                                    name="description" #reviewDescription="ngModel"
                                                                    [(ngModel)]="reviewFormData.reviewDescription"
                                                                    required>
                                            <div *ngIf="reviewDescription.valid || reviewDescription.touched || WriteReviewform.submitted"
                                            class="text-danger">
                                            <div *ngIf="reviewDescription.errors && reviewDescription.errors.required"
                                                class="text-danger">
                                                Description must be required
                                            </div>
                                        </div></textarea>
                                                                <div class="row mt-2">
                                                                    <div
                                                                        class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary p-1 submitBtn"
                                                                            (click)="cancleReviewForm()">Cancel</button>
                                                                    </div>

                                                                    <div
                                                                        class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2">
                                                                        <button type="submit"
                                                                            class="btn btn-primary secGradient secGradient whiteClr  p-1 submitBtn"
                                                                            [disabled]="isButtonDisabled"
                                                                            [ngClass]="{'button--loading':isButtonDisabled}"><span
                                                                                class="button__text">POST</span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="text-center mt-2"
                                                *ngIf="!reviewRatingobject || reviewRatingobject.avgRating <= 0">No
                                                reviews
                                                available...</div>
                                            <div *ngIf="reviewresponse?.length > 0">
                                                <div class="row" style="line-height: 1.2;">
                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-9">
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3">
                                                        <div class="text-color f-13">Sort By</div>
                                                        <select class="box border-0 fw-bold f-14" name="selectedValue"
                                                            [(ngModel)]="selectedValue" (change)="getReviewList()">
                                                            <option value="Popularity">Popularity</option>
                                                            <option value="Latest">Latest</option>
                                                        </select>
                                                    </div>

                                                </div>

                                                <div *ngFor="let reviewlist of reviewresponse | paginate: { id: 'brandpag', itemsPerPage: pagination.numPerPage,
                                    currentPage: pagination.pageNum,totalItems:ratingCount} ;let i=index">

                                                    <div class="review-border mt-3">
                                                        <div class="dotted-border">
                                                            <div class="row">
                                                                <div
                                                                    class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                                                    <div class="">
                                                                        <span
                                                                            class="badge rounded bg-primary text-white p-2 fw-bold">
                                                                            {{reviewlist.name}}
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                                                    <div class="row">
                                                                        <div
                                                                            class="col-7 col-sm-7 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                                                            <strong
                                                                                class="fw-bold f-14">{{reviewlist.userName}}</strong>
                                                                            <div class="star-rating">
                                                                                <ng-container
                                                                                    *ngFor="let i of [1, 2, 3, 4, 5]">
                                                                                    <span class="star"
                                                                                        [class.filled]="i <= reviewlist.rating">&#9733;</span>
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                                                            <small
                                                                                class="text-color f-13">{{reviewlist.ratingDate
                                                                                |
                                                                                date}}</small>
                                                                        </div>

                                                                    </div>
                                                                    <p class="text-color f-13">
                                                                        {{reviewlist.reviewDescription}}
                                                                    </p>

                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="row mt-1" *ngIf="reviewresponse.length > 0">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end">
                                        <div class="text-right">
                                            <pagination-controls (pageChange)="getPagination($event)" id="brandpag">
                                            </pagination-controls>
                                        </div>
                                    </div>
                                </div> -->
                                            <div class="row mt-1" *ngIf="reviewresponse.length > 0"
                                                style="color: #96989c;">
                                                <div class="text-left d-flex">
                                                    <div *ngIf="reviewRatingCnt > 1"
                                                        (click)="changeReviewCount('decrease')">
                                                        <i class="fa-solid fa-arrow-left" style="color: #858a93;"></i>
                                                        &nbsp;
                                                    </div>
                                                    {{reviewRatingCnt}} of {{pageNo}}
                                                    <div *ngIf="reviewRatingCnt != pageNo "
                                                        (click)="changeReviewCount('increase')">
                                                        &nbsp;&nbsp;<i class="fa-solid fa-arrow-right"
                                                            style="color: #858a93;"></i>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12-col-lg-6 col-xl-3 col-xxl-3 mt-3">
                                <div class="cardscroll">
                                    <div class="card-one">
                                        <div class="card-margin">
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                                    <!-- <div class="text-color">Select size for</div> -->

                                                </div>
                                                <div *ngIf="item.discountName"
                                                    class="col-6 col-sm-6 col-md-5 col-lg-5 col-xl-5 col-xxl-5 text-right">
                                                    <span class="percent-off text-danger p-1">
                                                        {{item.discountName}}

                                                    </span>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                    <strong>{{item.brandName}}</strong>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-wrap mt-2">
                                                <!-- First card (item.itemVarientBoList) -->
                                                <div *ngIf="item && item.itemVarientBoList && item.itemVarientBoList?.length > 0" class="badge varient">
                                                    <span class="image-height m-auto">
                                                        <div class="">
                                                            <strong class="f-13">{{ item.itemVarientBoList[0].attributeValue }}</strong>
                                                        </div>
                                                    </span>
                                                </div>
                                            
                                                <!-- Second card (item.otherChildItemsBOList) -->
                                                <div class="other-cards" *ngIf="item.stockInHand!=0 && item && item.otherChildItemsBOList && item.otherChildItemsBOList?.length > 0">
                                                    <ng-container *ngFor="let variant of item.otherChildItemsBOList; let ind = index">
                                                        <div class="badge varient varient-2">
                                                            <span class="image-height m-auto">
                                                                <div class="" (click)="selectVariant(variant)">
                                                                    <strong class="f-13">{{ variant.varientStr }}</strong>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                               
                                            </div>
                                            
                                            <hr class="m-2">
                                            <!-- <div class=" review-border w-100 mt-3">
                                    <div class="row p-2">
                                        <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                            <div><strong class="f-15 brandnamewidth">{{item.brandName}}</strong></div>
                                            <small class="text-success">Product Expiry : Mar 2025 </small>
                                        </div>
                                        <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                            <i class="fa fa-angle-down"></i>

                                        </div>
                                    </div>
                                </div> -->
                                            <div class="mt-3">
                                                <strong>{{currencySymbol}}{{item.finalPrice}}</strong>
                                                <small class="text-color mx-3"><del>
                                                        M.R.P. <span>{{currencySymbol}}</span>
                                                        <span>{{item.mrp}}</span>
                                                    </del></small>
                                                <!-- <select class="form-select thirdcard">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                </select> -->
                                            </div>
                                            <div *ngIf="!item.isAdded && item.isStockAvailable" (click)="addToCart(item)"
                                                class="btn btn-warning btnColor  mt-3 w-100" style="border-radius: 20px;">
                                                <strong>Add to Cart</strong>
                                            </div>
                                            <div  *ngIf="item.isInventory && !item.isStockAvailable"
                                                class="btn btn-warning btnColor  mt-3 w-100" style="border-radius: 20px;">
                                                <strong>Out Of Stock</strong>


                                            </div>
                                            <div class="cartCount homeItemCount" *ngIf="item.isAdded && item.isStockAvailable">
                                                <div class="flex alignCenter contentJustify">
                                                    <div class="primaryGradient incrementDecrement"
                                                        (click)="updateCartQuantity('decrease',item)"><i
                                                            class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="itemCount"><b>{{item.quantity}}</b></div>
                                                    <div class="primaryGradient incrementDecrement"
                                                        (click)="updateCartQuantity('increase',item)"><i
                                                            class="fa fa-angle-right" aria-hidden="true"></i></div>
                                                </div>
                                            </div>
                                            <!-- <div class="btn btn-dark  text-warnning  mt-2 w-100" style="border-radius: 20px;">
                                    <strong>Buy Now</strong>

                                </div> -->

                                        </div>
                                    </div>
                                    <div class="card-one mt-2">
                                        <div class="card-margin card-height2">
                                            <div style="line-height: 1.1;">
                                                <div><strong>We Deliver all over India</strong></div>
                                                <small class="text-color">(3-4 days avg time)</small>
                                            </div>
                                            <div>
                                                <div class="row border-bottom">
                                                    <div class="col-7 col-sm-7 col-md-86 col-lg-8 col-xl-8 col-xxl-8">
                                                        <input type="text" placeholder="Enter pincode"
                                                            name="deliveryPincode" minlength="6"
                                                            [(ngModel)]="shipmentData.deliveryPincode"
                                                          maxlength="6"
                                                            class="no-arrows box form-control border-0"
                                                            #pincode="ngModel" (input)="onInput($event)"
                                                            oninput="this.value =
                                                    !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">

                                                    </div>
                                                    <div class="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-1"
                                                        (click)="showDelivaryTime()">
                                                        <button type="submit" class="bg-warning border-0 rounded btnColor">
                                                            <strong class="px-2">Check</strong></button>
                                                    </div>
                                                </div>
                                                <h6 class="text-danger mt-1" *ngIf="!isLengthValid">Please Enter Valid
                                                    Pin Code</h6>
                                            </div>
                                            <div class="mt-2" *ngIf="delivaryResultData.deliveryMessage"
                                                style="line-height: 1.1;">

                                                <small class="text-color">
                                                    {{delivaryResultData.deliveryMessage}}
                                                </small>
                                            </div>




                                            <div *ngIf="delivaryResultData.deliveryDays" class="mt-2"
                                                style="line-height: 1.1;">
                                                <i class="fa-solid fa-truck-fast"></i>
                                                <small class="mx-1 mt-1">Get it in {{delivaryResultData.deliveryDays}}
                                                    days
                                                    <small class="text-color">
                                                        {{delivaryResultData.estimateDeliveryDate}}
                                                    </small></small><br>
                                                <!-- <small class="text-color"><small>you order before 24 May, 2:30 PM</small>
                                        </small> -->
                                                <small class="text-color">
                                                    {{delivaryResultData.deliveryMessage}}
                                                </small>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </article>
            </section>

            <!-- For mobile view -->
            <div class="mobileView">
                <div class="row  mb-3 three-cards">
                    <div class="col-12 col-sm-12 col-md-12-col-lg-6 col-xl-4 col-xxl-4 mt-3">
                        <div class=" card-one">
                            <div class="card-margin">
                                <div *ngIf="item.discountName" class="mb-2 mt-2">
                                    <span class="percent-off text-danger f-15 p-1 px-2">
                                        {{item.discountName}}

                                    </span>
                                </div>
                                <!-- <div *ngIf="item.itemImageBoList && item.itemImageBoList.length >0" class="text-center " >
                                <img id="myImg" src="{{dataService.appConstant.IMAGE_URL}}{{img.filePath}}"
                                    class="main-image-height " loading="lazy" alt="Image"
                                    onError="this.src='assets/img/errorLogo.png'"  onclick="openModal();currentSlide(0)">
                            </div> -->
                                <div class="img-small-wrap"
                                    *ngIf="item.itemImageBoList && item.itemImageBoList.length > 0">
                                    <section id="productDetailIMage-slider">
                                        <owl-carousel-o [options]="detailslider">
                                            <ng-container *ngFor="let img of item.itemImageBoList;let i=index">
                                                <ng-template carouselSlide>
                                                    <div class="SliderImage"
                                                        [ngClass]="{'outofstock-img': item.isInventory && item.stockInHand<=0}">
                                                        <img src="{{dataService.appConstant.IMAGE_URL}}{{img.filePath}}"
                                                            loading="lazy" onError="this.src='assets/img/errorLogo.png'"
                                                            onclick="openModal();currentSlide(0)"
                                                            class="main-image-height">
                                                           
                                                    </div>
                                                    <div class="out-of-stock-message" *ngIf="item.isInventory && !item.isStockAvailable">
                                                        Out of Stock
                                                    </div>
                                                </ng-template>
                                            </ng-container>
                                        </owl-carousel-o>
                                    </section>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="col-12 col-sm-12 col-md-12-col-lg-6 col-xl-5 col-xxl-5 mt-3">
                        <div class="">
                            <div class="card-one">
                                <div class="card-margin">
                                    <div class="row px-2">
                                        <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-9 col-xxl-9">
                                            <span *ngIf="item.brandImage">
                                                <a>
                                                    <img style="max-height: 28px"
                                                        src="{{dataService.appConstant.IMAGE_URL}}{{item.brandImage}}"
                                                        alt="Divine Nutrition Limited Edition logo"></a>
                                            </span>
                                            <!-- <span>
                                            |Accessories
                                        </span> -->
                                        </div>
                                        <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-3 col-xxl-3">
                                            <span *ngIf="reviewRatingobject.ratingCount" class="for-rettings p-1 f-13 ">
                                                {{reviewRatingobject.ratingCount}} Ratings

                                            </span>
                                        </div>
                                    </div>
                                    <div class="mt-2"><strong class="f-21"> {{item.itemName}}</strong></div>
                                    <p class="text-color"
                                        *ngIf="item && item.itemVarientBoList && item.itemVarientBoList?.length > 0">
                                        {{item.itemVarientBoList[0].attributeValue}} - {{item.brandName}}
                                    </p>
                                    <div class="d-flex flex-wrap mt-2">
                                        <!-- First card (item.itemVarientBoList) -->
                                        <div *ngIf="item && item.itemVarientBoList && item.itemVarientBoList?.length > 0" class="badge varient">
                                            <span class="image-height m-auto">
                                                <div class="">
                                                    <strong class="f-13">{{ item.itemVarientBoList[0].attributeValue }}</strong>
                                                </div>
                                            </span>
                                        </div>
                                    
                                        <!-- Second card (item.otherChildItemsBOList) -->
                                        <div class="other-cards" *ngIf="item.stockInHand!=0 && item && item.otherChildItemsBOList && item.otherChildItemsBOList?.length > 0">
                                            <ng-container *ngFor="let variant of item.otherChildItemsBOList; let ind = index">
                                                <div class="badge varient varient-2">
                                                    <span class="image-height m-auto">
                                                        <div class="" (click)="selectVariant(variant)">
                                                            <strong class="f-13">{{ variant.varientStr }}</strong>
                                                        </div>
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </div>
                                       
                                    </div>
                                    <div class="mt-3">
                                        <strong>{{currencySymbol}}{{item.finalPrice}}</strong>
                                        <!-- <small class="text-color mx-3"><del>
                                            M.R.P. <span>{{currencySymbol}}</span>
                                            <span>{{item.mrp}}</span>
                                        </del></small> -->
                                    </div>
                                    <div *ngIf="!item.isAdded && item.isStockAvailable" (click)="addToCart(item)"
                                        class="btn btn-warning btnColor mt-3 w-100" style="border-radius: 20px;">
                                        <strong>Add to Cart</strong>


                                    </div>
                                    <div  *ngIf="item.isInventory && !item.isStockAvailable"
                                    class="btn btn-warning btnColor mt-3 w-100" style="border-radius: 20px;">
                                    <strong>Out Of Stock</strong>


                                    </div>
                                    <div class="cartCount homeItemCount" *ngIf="item.isAdded && item.isStockAvailable">
                                        <div class="flex alignCenter contentJustify">
                                            <div class="primaryGradient incrementDecrement"
                                                (click)="updateCartQuantity('decrease',item)"><i
                                                    class="fa fa-angle-left" aria-hidden="true"></i>
                                            </div>
                                            <div class="itemCount"><b>{{item.quantity}}</b></div>
                                            <div class="primaryGradient incrementDecrement"
                                                (click)="updateCartQuantity('increase',item)"><i
                                                    class="fa fa-angle-right" aria-hidden="true"></i></div>
                                        </div>
                                    </div>
                                    <!-- <div class="btn btn-dark  text-warnning  mt-2 w-100" style="border-radius: 20px;">
                                    <strong>Buy Now</strong>

                                </div> -->

                                </div>
                            </div>
                            <div *ngIf="item.longDesc" class="card-one mt-1">
                                <div class="card-margin">
                                    <p>{{item.longDesc}}</p>

                                </div>

                            </div>
                            <div class="card-one mt-2">
                                <div class="card-margin card-height2">
                                    <div style="line-height: 1.1;">
                                        <div><strong>We Deliver all over India</strong></div>
                                        <small class="text-color">(3-4 days avg time)</small>
                                    </div>
                                    <div>
                                        <div class="row mt-1">
                                            <div class="col-7 col-sm-7 col-md-86 col-lg-8 col-xl-8 col-xxl-8">
                                                <input type="number" placeholder="Enter pincode" name="deliveryPincode"
                                                    [(ngModel)]="shipmentData.deliveryPincode"
                                                    onKeyPress="if(this.value.length==6) return false;"
                                                    class="no-arrows  form-control" #pincode="ngModel"
                                                    (input)="onInput($event)"
                                                    oninput="this.value =
                                                    !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">


                                            </div>
                                            <div class="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                                                (click)="showDelivaryTime()">
                                                <button type="submit" class="bg-warning border-0 rounded p-2 px-3 ">
                                                    <strong class="f-15">Check</strong></button>
                                            </div>
                                        </div>
                                        <h6 class="text-danger" *ngIf="!isLengthValid">Please Enter Valid Pin Code</h6>
                                    </div>
                                    <div class="mt-2" style="line-height: 1.1;">

                                        <small class="text-color">
                                            {{delivaryResultData.deliveryMessage}}
                                        </small>
                                    </div>

                                    <div *ngIf="delivaryResultData.deliveryDays" class="mt-2" style="line-height: 1.1;">
                                        <i class="fa-solid fa-truck-fast"></i>
                                        <small class="mx-1 mt-1">Get it in {{delivaryResultData.deliveryDays}} days
                                            <small class="text-color">
                                                {{delivaryResultData.estimateDeliveryDate}}
                                            </small></small><br>
                                        <small class="text-color"><small>you order before 24 May, 2:30 PM</small>
                                        </small>
                                    </div>

                                </div>

                            </div>
                            <div class="card-one mt-2">
                                <div class="card-margin card-height-review">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8 col-xxl-8">
                                            <div class="d-flex">
                                                <strong class="f-18">Reviews</strong>
                                                <div class="text-right"
                                                    *ngIf="reviewRatingobject && reviewRatingobject.avgRating > 0">
                                                    <!-- <span class="reviewRating">{{reviewRatingobject.avgRating}} <i
                                                        class="fa fa-star changeStartClr" aria-hidden="true"></i></span> -->
                                                    <span class="mx-1 f-13 text-secondary">
                                                        ({{ratingCount}}Review )
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class=" boder-card mt-2 mb-2" *ngIf="!item.isReviewed && item.isPurchaseItem">
                                        <div class="p-3">

                                            <span *ngIf="!item.isReviewed && item.isPurchaseItem && !reviewForm"
                                                class="d-flex"><i class="fa fa-edit"></i>
                                                <h6 class="fw-bold cursorPointer mx-1" (click)="writeReview()"> Write an
                                                    Review </h6>

                                            </span>
                                            <div>
                                                <form name="WriteReviewform" #WriteReviewform="ngForm"
                                                    (ngSubmit)="WriteReviewform.valid && saveReviewforms(WriteReviewform)">
                                                    <div *ngIf="reviewForm">
                                                        <div class="">
                                                            <ul class="rate-area">
                                                                <input type="radio" id="5-star" name="crating"
                                                                    [(ngModel)]="reviewFormData.rating" value="5">
                                                                <label for="5-star" title="Amazing">5
                                                                    stars</label>
                                                                <input type="radio" id="4-star" name="crating"
                                                                    [(ngModel)]="reviewFormData.rating" value="4">
                                                                <label for="4-star" title="Good">4
                                                                    stars</label>
                                                                <input type="radio" id="3-star" name="crating"
                                                                    [(ngModel)]="reviewFormData.rating" value="3">
                                                                <label for="3-star" title="Average">3
                                                                    stars</label>
                                                                <input type="radio" id="2-star" name="crating"
                                                                    [(ngModel)]="reviewFormData.rating" value="2">
                                                                <label for="2-star" title="Not Good">2
                                                                    stars</label>
                                                                <input type="radio" id="1-star"
                                                                    [(ngModel)]="reviewFormData.rating" name="crating"
                                                                    value="1" aria-required="true">
                                                                <label for="1-star" title="Bad">1
                                                                    star</label>
                                                            </ul>
                                                        </div>
                                                        <input type="text" placeholder="write title name"
                                                            class="form-control bg-white boder-card mt-2"
                                                            #reviewTitle="ngModel"
                                                            [(ngModel)]="reviewFormData.reviewTitle" name="reviewTitle"
                                                            required>
                                                        <div *ngIf="reviewTitle.valid || reviewTitle.touched || WriteReviewform.submitted"
                                                            class="text-danger">
                                                            <div *ngIf="reviewTitle.errors && reviewTitle.errors.required"
                                                                class="text-danger">
                                                                Title must be required
                                                            </div>
                                                        </div>
                                                        <textarea type="text" placeholder="write your review"
                                                            class="form-control bg-white boder-card mt-2"
                                                            name="description" #reviewDescription="ngModel"
                                                            [(ngModel)]="reviewFormData.reviewDescription" required>
                                            <div *ngIf="reviewDescription.valid || reviewDescription.touched || WriteReviewform.submitted"
                                            class="text-danger">
                                            <div *ngIf="reviewDescription.errors && reviewDescription.errors.required"
                                                class="text-danger">
                                                Description must be required
                                            </div>
                                        </div></textarea>
                                                        <div class="row mt-2">
                                                            <div
                                                                class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2">
                                                                <button type="submit"
                                                                    class="btn btn-secondary p-1 whiteClr  submitBtn"
                                                                    (click)="cancleReviewForm()">Cancel</button>
                                                            </div>

                                                            <div
                                                                class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2">
                                                                <button type="submit"
                                                                    class="btn btn-primary secGradient secGradient whiteClr  p-1 submitBtn"
                                                                    [disabled]="isButtonDisabled"
                                                                    [ngClass]="{'button--loading':isButtonDisabled}"><span
                                                                        class="button__text">POST</span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="text-center mt-2"
                                        *ngIf="!reviewRatingobject || reviewRatingobject.avgRating <= 0">No reviews
                                        available...</div>
                                    <div *ngIf="reviewresponse?.length > 0">
                                        <div class="row" style="line-height: 1.2;">
                                            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-9"></div>
                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3">
                                                <div class="text-color f-13">Sort By</div>
                                                <select class="box border-0 fw-bold f-14" name="selectedValue"
                                                    [(ngModel)]="selectedValue" (change)="getReviewList()">
                                                    <option value="Popularity">Popularity</option>
                                                    <option value="Latest">Latest</option>
                                                </select>
                                            </div>

                                        </div>

                                        <!-- <div *ngFor="let reviewlist of reviewresponse | paginate: { id: 'brandpag', itemsPerPage: pagination.numPerPage,
                                    currentPage: pagination.pageNum,totalItems:listcount} ;let i=index"> -->
                                        <div *ngFor="let reviewlist of reviewresponse | paginate: { id: 'brandpag', itemsPerPage: pagination.numPerPage,
                                    currentPage: pagination.pageNum,totalItems:ratingCount} ;let i=index">

                                            <div class="review-border mt-3">
                                                <div class="dotted-border">
                                                    <div class="row">
                                                        <div
                                                            class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                                            <div class="">
                                                                <span
                                                                    class="badge rounded bg-primary text-white p-2 fw-bold">
                                                                    {{reviewlist.name}}
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                                            <div class="row">
                                                                <div
                                                                    class="col-7 col-sm-7 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                                                    <strong
                                                                        class="fw-bold f-14">{{reviewlist.userName}}</strong>
                                                                    <div class="star-rating">
                                                                        <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
                                                                            <span class="star"
                                                                                [class.filled]="i <= reviewlist.rating">&#9733;</span>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-5 col-sm-5 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                                                    <small
                                                                        class="text-color f-13">{{reviewlist.ratingDate
                                                                        |
                                                                        date}}</small>
                                                                </div>

                                                            </div>
                                                            <p class="text-color f-13">{{reviewlist.reviewDescription}}
                                                            </p>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row mt-1" *ngIf="reviewresponse?.length > 0">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end">
                                            <div class="text-right">
                                                <pagination-controls (pageChange)="getPagination($event)" id="brandpag">
                                                </pagination-controls>
                                            </div>

                                        </div>
                                    </div> -->
                                    <div class="row mt-1" *ngIf="reviewresponse.length > 0" style="color: #96989c;">
                                        <div class="text-left d-flex">
                                            <div *ngIf="reviewRatingCnt > 1" (click)="changeReviewCount('decrease')">
                                                <i class="fa-solid fa-arrow-left" style="color: #858a93;"></i> &nbsp;
                                            </div>
                                            {{reviewRatingCnt}} of {{pageNo}}
                                            <div *ngIf="reviewRatingCnt != pageNo "
                                                (click)="changeReviewCount('increase')">
                                                &nbsp;&nbsp;<i class="fa-solid fa-arrow-right"
                                                    style="color: #858a93;"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--------RELATED PRODUCT DEKSTOP VIEW-------->
            <section class="desktopView">
                <div *ngIf="item.ecommItemBOList && item.ecommItemBOList.length > 0">
                    <!-- <strong class="margin-left f-18"> Similar Products</strong> -->
                    <div *ngIf="item.ecommItemBOList && item.ecommItemBOList.length <= 4" class="container-fluid">
                        <div class="mb-4"><strong class="margin-left f-21"> Similar Products</strong></div>
                        <div class="row mb-3">
                            <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 cursorPointer"
                                *ngFor="let prod of item.ecommItemBOList | slice:0:4;let relatedProdIndex=index">
                                <div>
                                    <div [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}"
                                        (click)="itemDetail(prod)" class="card-margin card-height"
                                        routerLink="/product-detail">
                                        <!-- src="{{dataService.appConstant.IMAGE_URL}}{{prod.imageFilePath}}" -->
                                        <div [ngClass]="{'margin-discount':prod.discountName == null}">
                                            <span *ngIf="prod.discountName" class="">
                                                <div class="badge percent-off p-1 text-danger">
                                                    <label class="mt-0 ">
                                                        <span>{{prod.discountName}}</span></label>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="text-center">
                                            <img class="card-img-top"
                                                src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-300,h-300,cm-pad_resize/{{prod.imageFilePath}}"
                                                loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                                        </div>
                                        <div class="">
                                            <div class="text-color f-13 brandnamewidth" [tooltip]="prod.itemName"
                                                placement="top" show-delay="500">
                                                {{prod.brandName}}
                                            </div>
                                            <div class="f-15 namewidth" [tooltip]="prod.itemName" placement="top"
                                                show-delay="500">
                                                <strong> {{prod.itemName}}</strong>
                                            </div>
                                            <div class="f-13">
                                                <span class="">{{currencySymbol}}
                                                    {{prod.finalPrice}}</span>
                                                <span class="text-color mx-3"><del><span>{{currencySymbol}}</span>
                                                        <span>{{prod.mrp}}</span></del></span>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div>


                        </div>
                    </div>
                    <section *ngIf="item.ecommItemBOList && item.ecommItemBOList.length > 4"
                        class="pt-2 mb-4 best-offers-product cursorPointer  animated fadeDown" id="special-offer-sec">
                        <strong class="margin-left f-21"> Similar Products</strong>
                        <div>
                            <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
                                <strong class=" f-21"> Similar Products</strong>
                                <ng-container *ngFor="let prod of item.ecommItemBOList;let itemIndex=index">
                                    <ng-template carouselSlide>
                                        <div class="margin-top mx-2">
                                            <div [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}"
                                                (click)="itemDetail(prod)" class="card-margin card-height"
                                                routerLink="/product-detail">
                                                <div [ngClass]="{'margin-discount':prod.discountName == null}">
                                                    <span *ngIf="prod.discountName" class="">
                                                        <div class="badge percent-off p-1 text-danger">
                                                            <label class="mt-0 ">
                                                                <span>{{prod.discountName}}</span></label>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="text-center">
                                                    <img class="card-img-top"
                                                        src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-300,h-300,cm-pad_resize/{{prod.imageFilePath}}"
                                                        loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                                                </div>
                                                <div class="">
                                                    <div class="text-color f-13 brandnamewidth"
                                                        [tooltip]="prod.brandName" placement="top" show-delay="500">
                                                        {{prod.brandName}}
                                                    </div>
                                                    <div class="f-14 namewidth" [tooltip]="prod.itemName"
                                                        placement="top" show-delay="500">
                                                        <strong> {{prod.itemName}}</strong>
                                                    </div>
                                                    <div class="mobilesize f-13 mt-1">
                                                        <span class="">{{currencySymbol}}
                                                            {{prod.finalPrice}}</span>
                                                        <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                                                                <span>{{prod.mrp}}</span></del></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </section>


                </div>
                <div *ngIf="recentlyViewList && recentlyViewList.length >0">
                    <div class="container-fluid">
                        <div class="  ">
                            <strong class=" f-21"> Recently Viewed Product 1</strong>
                        </div>
                        <div *ngIf="recentlyViewList && recentlyViewList.length <= 4" class="row mb-4">
                            <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 cursorPointer no-padding"
                                *ngFor="let prod of recentlyViewList | slice:0:4;let relatedProdIndex=index">
                                <div>
                                    <div [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}"
                                        (click)="itemDetail(prod)" class="card-margin card-height"
                                        routerLink="/product-detail">
                                        <div [ngClass]="{'margin-discount':prod.discountName == null}">
                                            <span *ngIf="prod.discountName" class="">
                                                <div class="badge percent-off p-1 text-danger">
                                                    <label class="mt-0 ">
                                                        <span>{{prod.discountName}}</span></label>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="text-center">
                                            <img class="card-img-top"
                                                src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-300,h-300,cm-pad_resize/{{prod.imageFilePath}}"
                                                loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                                        </div>
                                        <div class="">
                                            <div class="text-color f-13 brandnamewidth" [tooltip]="prod.itemName"
                                                placement="top" show-delay="500">
                                                {{prod.brandName}}
                                            </div>
                                            <div class="f-15 namewidth" [tooltip]="prod.itemName" placement="top"
                                                show-delay="500">
                                                <strong> {{prod.itemName}}</strong>
                                            </div>
                                            <div class="f-13">
                                                <span class="">{{currencySymbol}}
                                                    {{prod.finalPrice}}</span>
                                                <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                                                        <span>{{prod.mrp}}</span></del></span>

                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <section *ngIf="recentlyViewList && recentlyViewList.length > 4"
                            class="pt-2 mb-4 best-offers-product cursorPointer  animated fadeDown"
                            id="special-offer-sec">
                            <div>
                                <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
                                    <ng-container *ngFor="let prod of recentlyViewList;let itemIndex=index">
                                        <ng-template carouselSlide>
                                            <div class="margin-top mx-2">
                                                <div [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}"
                                                    (click)="itemDetail(prod)" class="card-margin card-height"
                                                    routerLink="/product-detail">
                                                    <div [ngClass]="{'margin-discount':prod.discountName == null}">
                                                        <span *ngIf="prod.discountName" class="">
                                                            <div class="badge percent-off p-1 text-danger">
                                                                <label class="mt-0 ">
                                                                    <span>{{prod.discountName}}</span></label>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div class="text-center">
                                                        <img class="card-img-top"
                                                            src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-300,h-300,cm-pad_resize/{{prod.imageFilePath}}"
                                                            loading="lazy"
                                                            onError="this.src='assets/img/errorLogo.png'">
                                                    </div>
                                                    <div class="">
                                                        <div class="text-color f-13 brandnamewidth"
                                                            [tooltip]="prod.itemName" placement="top" show-delay="500">
                                                            {{prod.brandName}}
                                                        </div>
                                                        <div class="f-15 namewidth" [tooltip]="prod.itemName"
                                                            placement="top" show-delay="500">
                                                            <strong> {{prod.itemName}}</strong>
                                                        </div>
                                                        <div class="f-13">
                                                            <span class="">{{currencySymbol}}
                                                                {{prod.finalPrice}}</span>
                                                            <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                                                                    <span>{{prod.mrp}}</span></del></span>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                        </section>
                        <div>


                        </div>
                    </div>
                </div>
            </section>
            <!-- For mobile view -->
            <section class="mobileView">
                <div *ngIf="item.ecommItemBOList && item.ecommItemBOList.length > 0">
                    <section class="pt-2 mb-4 best-offers-product cursorPointer  animated fadeDown"
                        id="special-offer-sec">
                        <strong class="margin-left f-21"> Similar Products</strong>
                        <div>
                            <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
                                <strong class="margin-left f-21"> Similar Products</strong>
                                <ng-container *ngFor="let prod of item.ecommItemBOList;let itemIndex=index">
                                    <ng-template carouselSlide>
                                        <div class="margin-top mx-2">
                                            <div [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}"
                                                (click)="itemDetail(prod)" class="card-margin card-height"
                                                routerLink="/product-detail">
                                                <div class="mb-2">
                                                </div>
                                                <div [ngClass]="{'margin-discount1':prod.discountName == null}">
                                                    <span *ngIf="prod.discountName" class="">
                                                        <div class="badge percent-off p-1 text-danger">
                                                            <label class="mt-0 ">
                                                                <span>{{prod.discountName}} </span></label>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="text-center mt-1">
                                                    <img class="card-img-top"
                                                        src="{{dataService.appConstant.IMAGE_URL}}{{prod.imageFilePath}}"
                                                        loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                                                </div>
                                                <div class="">
                                                    <div class="text-color f-13 brandnamewidth"
                                                        [tooltip]="prod.brandName" placement="top" show-delay="500">
                                                        {{prod.brandName}}
                                                    </div>
                                                    <div class="f-14 namewidth" [tooltip]="prod.itemName"
                                                        placement="top" show-delay="500">
                                                        <strong> {{prod.itemName}}</strong>
                                                    </div>
                                                    <div class="mobilesize  f-10 mt-1">
                                                        <span class="">{{currencySymbol}}
                                                            {{prod.finalPrice}}</span>
                                                        <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                                                                <span>{{prod.mrp}}</span></del></span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </section>


                </div>
                <div *ngIf="recentlyViewList && recentlyViewList.length > 0">
                    <section class="pt-2 mb-4 best-offers-product cursorPointer  animated fadeDown"
                        id="special-offer-sec">
                        <strong class="margin-left f-21"> Recently Viewed Product</strong>
                        <div>
                            <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
                                <ng-container *ngFor="let prod of recentlyViewList;let itemIndex=index">
                                    <ng-template carouselSlide>
                                        <div class="margin-top mx-2">
                                            <div [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}"
                                                (click)="itemDetail(prod)" class="card-margin card-height"
                                                routerLink="/product-detail">
                                                <div [ngClass]="{'margin-discount':prod.discountName == null}">
                                                    <div *ngIf="prod.discountName"
                                                        class="badge percent-off p-1 text-danger">
                                                        <label class="mt-0 ">
                                                            <span>{{prod.discountName}}</span></label>
                                                    </div>
                                                </div>
                                                <div class="text-center mt-1">
                                                    <img class="card-img-top"
                                                        src="{{dataService.appConstant.IMAGE_URL}}{{prod.imageFilePath}}"
                                                        loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                                                </div>
                                                <div class="">
                                                    <div class="text-color f-13 brandnamewidth"
                                                        [tooltip]="prod.brandName" placement="top" show-delay="500">
                                                        {{prod.brandName}}
                                                    </div>
                                                    <div class="f-14 namewidth" [tooltip]="prod.itemName"
                                                        placement="top" show-delay="500">
                                                        <strong> {{prod.itemName}}</strong>
                                                    </div>
                                                    <div class="mobilesize  f-10 mt-1">
                                                        <span class="">{{currencySymbol}}
                                                            {{prod.finalPrice}}</span>
                                                        <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                                                                <span>{{prod.mrp}}</span></del></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </section>


                </div>
            </section>

        </div>
    </div>
    <skeleton-loader *ngIf="isLoading"></skeleton-loader>
    <div id="myModal" class="modal">
        <span class="close cursor" onclick="closeModal()">&times;</span>
        <div class="modal-content ">

            <div class="mySlides text-center product " *ngFor="let img of item.itemImageBoList;let i=index">
                <img src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy"
                    onError="this.src='assets/img/errorLogo.png'" class="image-popup">
                <div class="zoom-overlay"></div>
            </div>

            <div class="column mt-2 d-flex justify-content-center">
                <div *ngFor="let img of item.itemImageBoList;let i=index" class="badge rounded-4"
                    [ngClass]="{'rounded-3': i == currentimageIndexpopup}">
                    <span class="image-height">
                        <span class="inner-span">
                            <img src="{{dataService.appConstant.IMAGE_URL}}{{img.filePath}}" loading="lazy"
                                onError="this.src='assets/img/errorLogo.png'" (click)="openImg(i)" class="review-image">
                        </span>
                    </span>
                </div>
            </div>

            <!-- <section class="content">
              <article class="demo-area" (mouseenter)="a()">
              <img class="demo-trigger"
              src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
              [attr.data-zoom]="dataService.appConstant.IMAGE_URL + item.imageFilePath + '?w=1000&ch=DPR&dpr=2'">

              <div class="detail">
               <section>
                  <h3>Men's Watch - Drift Demo</h3>
                  <p>Specifications:</p>
                  <ul>
                    <li>Hover over image</li>
                    <li>35 mm stainless steel case</li>
                    <li>Stainless link bracelet with buckle closure</li>
                    <li>Water resistant to 100m</li>
                  </ul>
                </section>
              </div>
              </article>
            </section> -->
        </div>
    </div>
</section>