<!-- <footer>
  <div class="container-fluid">
    <div class="container">
      <div class="row">

        <div class="col-12 col-sm-3 col-md-3  col-lg-3 col-xl-3 col-xxl-3  logo cursorPointer" routerLink="">
          <div width="100%" height="auto" class="orgName1" *ngIf="orgnizationData && orgnizationData.organizationName">
            {{orgnizationData.organizationName}}
          </div>
          <ul class="Social-icons">
            <li><a *ngIf="footerData && footerData.fbLink" href="{{footerData.fbLink}}"><i
                  class="fa-brands fa-facebook-f"></i></a></li>
            <li><a *ngIf="footerData && footerData.instagramLink" href="{{footerData.instagramLink}}"><i
                  class="fa-brands fa-instagram"></i></a></li>
          </ul>
          <div class="d-flex ">
            <a *ngIf="footerData && footerData.playStoreLink" href="{{footerData.playStoreLink}}">
              <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
            </a>
            <a *ngIf="footerData && footerData.appStoreLink" href="{{footerData.appStoreLink}}">
              <img class="playstore-icon" src="assets/img/AppStore.png" alt="Logo">
            </a>
          </div>
        </div>

        <div class="col-12 col-md-2">
          <h5><b>OUR STORES</b></h5>
          <ul routerLink="/web/store_location" class="cursorPointer privacy">
              <li *ngFor="let store of storesList">
                  <a>{{store.wareHouseName}}</a>
              </li>
          </ul>
      </div>

        <div class=" col-12 col-md-2 cursorPointer">
          <h5><b>ABOUT US</b></h5>
          <ul class="privacy">
            <li>
              <a routerLink="/" aria-hidden="true">Home</a>
            </li>
            <li routerLink="/web/store_location">
              <a>Store Locator</a>
            </li>
            <li (click)="goToPrivacyPolicy('Privacy_Policy')">
              <a>Privacy Policy</a>
            </li>
            <li (click)="goToPrivacyPolicy('Terms_and_Conditions')">
              <a>Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div class="col-12 col-md-2">
          <h5><b>CATEGORIES</b></h5>
          <ul>
              <li class="cursorPointer privacy" *ngFor="let category of categoryList | slice:0:7;let last=last"
                  routerLink="/web/ag/products"
                  [queryParams]="{category:category.name,category_uid:category.categoryUid,onPage: true }"
                  (click)="scrollTop()">
                  <a>{{category.name}}</a>
              </li>
          </ul>
      </div>

        <div class="col-12  col-md-3 col-lg-3 col-xl-3 col-xxl-3 cursorPointer">
          <h5><b>Contact Us</b></h5>
          <div class="privacy">
            <ul *ngIf="orgnizationData">
              <li>
                <img src="assets/img/Mobile.png" alt="Icon" width="100%" height="auto" class="footer-icon">
                <a href="tel:+{{orgnizationData.mobile}}"> +91 {{orgnizationData.mobile}}</a>
              </li>
              <li>
                <img src="assets/img/Email.png" alt="Icon" width="100%" height="auto" class="footer-icon">
                <a href="mailto: {{orgnizationData.email}}"> {{orgnizationData.email}}</a>
              </li>
              <br>
              <li>
                <a *ngIf="footerData && footerData.addressLine1"
                  href="mailto: {{ organizationAddress.addressLine1 }}">{{ organizationAddress.addressLine1 }} </a>
                <a *ngIf="footerData && footerData.addressLine2"
                  href="mailto: {{ organizationAddress.addressLine2 }}">{{ organizationAddress.addressLine2 }} </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <hr>
      <section id="footer-copyright ">
        <div class="container">
          <div class="row">
            <div class="col-md-8 copy-right cursorPointer" *ngIf="orgnizationData && orgnizationData.organizationName">
              <p>Copyright©2023. All rights reserved @{{orgnizationData.organizationName}}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer> -->

<footer>
  <div class="container footer-row">
    <div class="row">
      <div class="col-md-2 footer-title text-center" *ngIf="orgnizationData">
        <h4 class="card-img-top" routerLink="">
          <!-- <b
                      *ngIf="orgnizationData && orgnizationData.organizationName">{{orgnizationData.organizationName}}</b> -->
          <!-- <img class="review-image"
          src="{{dataService.appConstant.IMAGE_URL}}{{orgnizationData.filePath}}"
          loading="lazy" onError="this.src='assets/img/errorLogo.png'"> -->
          <!-- <img class="review-image" src='assets/img/logo-2.png'> -->
          <img class="review-image" src="{{dataService.appConstant.IMAGE_URL}}{{orgnizationData.filePath}}"
            loading="lazy" onError="this.src='assets/img/errorLogo.png'">
          <!-- {{orgnizationData.organizationName}} -->
        </h4>
        <ul class="Social-icons">
          <li><a *ngIf="footerData && footerData.fbLink" href="{{footerData.fbLink}}"><i
                class="fa-brands fa-facebook-f"></i></a></li>
          <li><a *ngIf="footerData && footerData.instagramLink" href="{{footerData.instagramLink}}"><i
                class="fa-brands fa-instagram"></i></a></li>
         <li><a *ngIf="footerData && footerData.linkedinLink" href="{{footerData.linkedinLink}}"><i
                  class="fa-brands fa-linkedin"></i></a></li>
         
        </ul>
        <div class="d-flex">
          <a *ngIf="footerData && footerData.playStoreLink" href="{{footerData.playStoreLink}}">
            <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
          </a>
          <a *ngIf="footerData && footerData.appStoreLink" href="{{footerData.appStoreLink}}">
            <img class="playstore-icon" src="assets/img/AppStore.png" alt="Logo">
          </a>
        </div>
      </div>
      <!-- <div class="col-md-2 footer-title">
              <h2>OUR STORES</h2>
              <ul routerLink="/web/store_location" class="cursorPointer">
                  <li *ngFor="let store of storesList">
                      <a>{{store.wareHouseName}}</a>
                  </li>
              </ul>
          </div> -->
      <div class="col-md-3 text footer-title">
        <div>
          <h2>CATEGORIES</h2>
          <ul>
            <li class="cursorPointer" *ngFor="let category of categoryList | slice:0:7;let last=last"
              routerLink="/web/ag/products"
              [queryParams]="{category:category.name,category_uid:category.categoryUid,onPage: true }"
              (click)="scrollTop()">
              <a>{{category.name}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-3  text footer-title">
        <div>
          <h2>BRANDS</h2>
          <ul>
            <li class="cursorPointer" *ngFor="let brand of brandList | slice:0:7;let last=last"
              routerLink="/web/ag/products"
              [queryParams]="{brand:brand.brandName,brand_uid:brand.brandUid,onPage: true }" (click)="scrollTop()">
              <a>{{brand.brandName}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 footer-title">
        <h2>COMPANY</h2>
        <ul class="cursorPointer">
           <li *ngIf="footerData.isShowAboutUs" (click)="goToPrivacyPolicy('About_Us')">
               <a>About Us</a>
            </li>
          <li *ngIf="footerData.isShowPrivacyPolicy" (click)="goToPrivacyPolicy('Privacy_Policy')">
            <a>Privacy Policy</a>
          </li>
          <li *ngIf="footerData.isShowTermsConditions" (click)="goToPrivacyPolicy('Terms_and_Conditions')">
            <a>Terms and Conditions</a>
          </li>
          <li *ngIf="footerData.isShowReturnPolicy" (click)="goToPrivacyPolicy('Return_Policy')">
            <a>Refund and Return Policy</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 text-width footer-title">
        <div>
          <h2>Contact US</h2>
          <ul *ngIf="orgnizationData" class="cursorPointer">
            <li>
              <img src="assets/img/Mobile.png" alt="Icon" width="100%" height="auto" class="footer-icon">
              <a href="tel:+{{orgnizationData.mobile}}"> +91 {{orgnizationData.mobile}}</a>
              <br>
            </li>
            <li>
              <img src="assets/img/Email.png" alt="Icon" width="100%" height="auto" class="footer-icon">
              <a href="mailto: {{orgnizationData.email}}"> {{orgnizationData.email}}</a>
              <br>
            </li>
            <!-- <li>
              <img src="assets/img/Website.png" alt="Icon" width="100%" height="auto" class="footer-icon">
              <a href="{{orgnizationData.website}}"> {{orgnizationData.website}}</a>
          </li>

          <li *ngIf="orgnizationData.adressList && orgnizationData.adressList.length>0">
            <img src="assets/img/map.png" alt="Icon" width="100%" height="auto" class="footer-icon">
            <a> {{orgnizationAddr | titlecase}}</a>
        </li> -->
      
        <div class="d-flex">
          <a href="https://apnagrahak.com/" target="_blank">
          <img class="poweredby" src="assets/img/Powered By AG 5.png" alt="Logo"></a>
      </div>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <section id="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-8 copy-right" *ngIf="orgnizationData && orgnizationData.organizationName">
          <p class="" *ngIf="footerData && footerData.copyright">{{footerData.copyright}}</p>
        </div>
        <!-- <div class="col-md-4 copy-top">
                  <img src="assets/img/Payments.webp" alt="Banks" width="100%" height="auto">
              </div> -->
        <div class="col-md-8 copy-right cursorPointer" *ngIf="orgnizationData && orgnizationData.organizationName">
          <p>Copyright © 2023 <a href="https://apnagrahak.com/" target="_blank">Apna Grahak.</a> All Rights Reserved.</p>
        </div>
        <div class="col-md-4 copy-top">
          <!-- <img src="assets/img/Payments.webp" alt="Banks" width="100%" height="auto"> -->
        </div>
      </div>
    </div>
  </section>
</footer>