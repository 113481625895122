<!--Main Slider-->

<section id="mobileHeader" class="mobileView">
  <div class="container-fluid primaryGradient">
    <div class="row mobileFixedHeader primaryGradient">
      <div class="col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3 mobileHeadPadT" data-toggle="modal"
        data-target="#mobileSideBar">
        <i class="fa fa-bars mMenuToggle " aria-hidden="true"></i>
      </div>
      <div class="col-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 tCenter mobileHeadPadT">
        <div width="100%" height="auto" class="orgName" *ngIf="orgnizationData && orgnizationData.organizationName">
          {{orgnizationData.organizationName}}
        </div>
        <!-- <img src="assets/img/Logo.png" class="mLogo" alt="Logo"> -->
        <span *ngIf="warehouseData && warehouseData.warehouseListCount > 1" class="warehouseName"
          (click)="changeWare()">{{warehouseData.wareHouseName}}&nbsp;<i class="fa fa-pencil wareedit"></i></span>
      </div>
      <div class="col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3 tRight mobileHeadPadT" routerLink="/web/ag/profile">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="w-100 d-flex">
          <input type="text" class="form-control1 " placeholder="What you want to buy...." [(ngModel)]="searchProduct"
            name="searchProduct" (ngModelChange)="clearSearch()">
          <div class="" type="button" (click)="searchProductByKey()">
            <span class="d-flex  btnpadding badge bg-dark text-white">
              <i class="fas fa-search  mx-1"></i>
              Search</span>
          </div>
        </div>
      </div>
      <div class="d-flex col-md-3">

        <a *ngIf="footerData && footerData.playStoreLink" href="{{footerData.playStoreLink}}">
          <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
        </a>
        <a *ngIf="footerData && footerData.appStoreLink" href="{{footerData.appStoreLink}}">
          <img class="appstore-icon" src="assets/img/AppStore.png" alt="Logo">
        </a>
      </div>
    </div>
  </div>
</section>
<a id="backToTopbuttonList"></a>


<body>
  <a id="backToTopbutton"></a>

  <skeleton-loader *ngIf="isLoading"></skeleton-loader>
  <section class="homePageSection desktopView" *ngIf="previewDataList && previewDataList.length>0"
    (scroll)="onScrollOrder($event)">
    <ng-container>
      <owl-carousel-o [options]="advertisingSlider" *ngIf="mainSlider.cmsSlider.cmsSliderContentList?.length > 0">
        <ng-container *ngFor="let imgs of mainSlider.cmsSlider.cmsSliderContentList;let i=index">
          <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
            <div class="sliderimage">
              <img src="{{dataService.appConstant.IMAGE_URL}}{{imgs.imageFilePath}}" loading="lazy"
                onError="this.src='assets/img/errorLogo.png'" (click)="onBannerClick(imgs)">
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </ng-container>
  </section>

  <section class="mainSlider mobileView animated fadeIn " id="mainSlider">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div *ngIf="previewDataList && previewDataList.length>0" (scroll)="onScrollOrder($event)">
            <owl-carousel-o [options]="advertisingSlider" *ngIf="mainSlider.cmsSlider.cmsSliderContentList?.length > 0">
              <ng-container *ngFor="let imgs of mainSlider.cmsSlider.cmsSliderContentList;let i=index">
                <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
                  <div>
                    <img src="{{dataService.appConstant.IMAGE_URL}}{{imgs.imageFilePath}}" loading="lazy"
                      onError="this.src='assets/img/errorLogo.png'" (click)="onBannerClick(imgs)">
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </section>


  <a id="backToTopbuttonList"></a>
  <div class="container-fluid">
    <!--Top Categories For Desktop View -->
    <div *ngIf="topCategoryView && topCategoryView.cmsSectionBO   && topCategoryView.cmsSectionBO.cmsSectionContentBOList 
    && topCategoryView.cmsSectionBO.cmsSectionContentBOList.length > 0">
      <section class="pt-5 mb-5 desktopView animated fadeIn"
        *ngFor="let pageSection of topCategoryView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
        <div class="mx-1" *ngIf="pageSection.catList?.length > 0">
          <div class="row ">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
              <div class="top-categories ">
                <h3>{{pageSection.title}}</h3>
                <p>{{pageSection.description}}
                </p>
                <div class="themeColor whiteBg ellipseBtn rectangleBannerBtn floatR" routerLink="/web/ag/categories">
                  <b> All Categories
                    &nbsp;&nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></b>
                </div>
                <br>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">

              <div class="row">
                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 product-category"
                  *ngFor="let category of pageSection.catList | slice:0:4" routerLink="/web/ag/products"
                  [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                  <div class="card-product ">
                    <div class="categoryImage">
                      <img class="card-img-top"
                        src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-{{imgWidth}},h-{{imgHeight}},cm-pad_resize/{{category.filePath}}"
                        loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                    </div>
                    <div class="card-block-product" routerLink="/web/ag/products"
                      [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                      <h5 class="text-bold-product">{{category.name}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 product-category catCol"
              *ngFor="let category of pageSection.catList | slice:4">
              <div class="card-product" routerLink="/web/ag/products"
                [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                <div class="categoryImage">
                  <img class="card-img-top"
                    src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-{{imgWidth}},h-{{imgHeight}},cm-pad_resize/{{category.filePath}}"
                    loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                </div>
                <div class="card-block-product">
                  <h5 class="text-bold-product">{{category.name}} </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!--Top Categories For Mobile View-->
    <div
      *ngIf="topCategoryView && topCategoryView.cmsSectionBO && topCategoryView.cmsSectionBO.cmsSectionContentBOList && topCategoryView.cmsSectionBO.cmsSectionContentBOList.length > 0">
      <section class="  mobileView animated fadeIn"
        *ngFor="let pageSection of topCategoryView.cmsSectionBO.cmsSectionContentBOList; let cmsSectionContentIndex = index;">
        <div class="container" *ngIf="pageSection.catList?.length > 0">
          <div class="row">
            <div class="col-12">
              <h3>{{ pageSection.title }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-wrap">
                <ng-container *ngFor="let category of pageSection.catList; let i=index">
                  <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" routerLink="/web/ag/products"
                    [queryParams]="{ category: category.name, category_uid: category.categoryUid, ecommPageUid: ecommPageUid }">
                    <div class="card-product1">
                      <div class="categoryImage1">
                        <img class="card-img-top1"
                          src="{{ dataService.appConstant.IMAGE_BASE_URL }}{{ category.filePath }}" loading="lazy"
                          onError="this.src='assets/img/errorLogo.png'">
                      </div>
                      <div class="card-block-product1" routerLink="/web/ag/products"
                        [queryParams]="{ category: category.name, category_uid: category.categoryUid, ecommPageUid: ecommPageUid }">
                        <h5 class="text-bold-product1">{{ category.name }}</h5>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    

    <!--Deal Of the Month 🔥-->
    <div *ngIf="bestSellerProduct && bestSellerProduct.cmsSectionBO && bestSellerProduct.cmsSectionBO.cmsSectionContentBOList 
   && bestSellerProduct.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
      <section class="  antimated fadeDown" id="special-offer-sec"
        *ngFor="let pageSection of bestSellerProduct.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
        <h3 class="recent-product-title mt-3">{{ pageSection.title}}
        </h3>
        <div>
          <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
            <strong class="margin-left f-18 "> Similar Products</strong>
            <ng-container *ngFor="let prod of pageSection.itemList;let itemIndex=index">
              <ng-template carouselSlide>
                <div class="margin-top mx-2 ">
                  <div
                    (click)="itemDetail(prod)" class="card-margin card-height" routerLink="/product-detail">
                    <div [ngClass]="{'margin-discount':prod.discountName == null}">
                      <div *ngIf="prod.discountName" class="badge percent-off p-1 text-danger">
                          <label class="mt-0 ">
                              <span>{{prod.discountName}}</span></label>
                      </div>
                      </div>
                    <div class="text-center mt-1">
                      <img class="card-img-top"  [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}"  src="{{dataService.appConstant.IMAGE_URL}}{{prod.imageFilePath}}"
                        loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                        <div class="out-of-stock-message" *ngIf="prod.isInventory && !prod.isStockAvailable">
                          Out of Stock
                        </div>
                    </div>
                    <div class="text-center">
                      <div class="text-color brandnamewidth" [tooltip]="prod.itemName" placement="top" show-delay="500">
                        {{prod.brandName}}
                      </div>
                      <div class="namewidth" [tooltip]="prod.itemName" placement="top" show-delay="500">
                        <strong> {{prod.itemName}}</strong>
                      </div>
                      <div class="price">
                        <span class="offer">{{currencySymbol}}
                          {{prod.finalPrice}}</span>
                        <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                            <span>{{prod.mrp}}</span></del></span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>

        </div>
      </section>
    </div>

    <!--Protine Dhamaka Offer-->
    <div *ngIf="topProductView && topProductView.cmsSectionBO   && topProductView.cmsSectionBO.cmsSectionContentBOList 
    && topProductView.cmsSectionBO.cmsSectionContentBOList.length > 0">
      <section class="best-offer pt-5  antimated fadeDown" id="special-offer-sec"
        *ngFor="let pageSection of topProductView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
        <h3 class="recent-product-title">{{ pageSection.title}}
        </h3>
        <div>

          <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
            <strong class="margin-left f-18"> Similar Products</strong>
            {{ pageSection.title}}
            <ng-container *ngFor="let prob of pageSection.itemList;let itemIndex=index">
              <ng-template carouselSlide>
                <div class="margin-top mx-2 ">
                  <div (click)="itemDetail(prob)"
                    class="card-margin card-height" routerLink="/product-detail">


                    
                      <div [ngClass]="{'margin-discount':prob.discountName == null}">
                        <div *ngIf="prob.discountName" class="badge percent-off p-1 text-danger">
                            <label class="mt-0 ">
                                <span>{{prob.discountName}}</span></label>
                        </div>
                        </div>
                    <div class="text-center mt-1">
                      <img class="card-img-top" [ngClass]="{'outofstock-img': prob.isInventory && !prob.isStockAvailable}" src="{{dataService.appConstant.IMAGE_URL}}{{prob.imageFilePath}}"
                        loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                        <div class="out-of-stock-message" *ngIf="prob.isInventory && !prob.isStockAvailable">
                          Out of Stock
                        </div>
                      </div>
                    <div class="text-center">
                      <div class="text-color brandnamewidth" [tooltip]="prob.itemName" placement="top" show-delay="500">
                        {{prob.brandName}}
                      </div>
                      <div class="namewidth" [tooltip]="prob.itemName" placement="top" show-delay="500">
                        <strong> {{prob.itemName}}</strong>
                      </div>
                      <div class="price">
                        <span class="offer">{{currencySymbol}}
                          {{prob.finalPrice}}</span>
                        <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                            <span>{{prob.mrp}}</span></del></span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>

        </div>
      </section>
    </div>

    <!--Reccomendation-->
    <div *ngIf="recommendationView && recommendationView.cmsSectionBO && recommendationView.cmsSectionBO.cmsSectionContentBOList 
&& recommendationView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
      <section class="  antimated fadeDown" id="special-offer-sec"
        *ngFor="let pageSection of recommendationView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
        <h3 class="recent-product-title mt-5">{{ pageSection.title}}
        </h3>
        <div>
          <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
            <strong class="margin-left f-18"> Similar Products</strong>

            <!-- {{ pageSection.title}} -->
            <ng-container *ngFor="let list of pageSection.itemList;let itemIndex=index">
              <ng-template carouselSlide>
                <div class="margin-top mx-2 ">
                  <div (click)="itemDetail(list)" class="card-margin card-height" routerLink="/product-detail">
                    <div [ngClass]="{'margin-discount':list.discountName == null}">
                      <div *ngIf="list.discountName" class="badge percent-off p-1 text-danger">
                          <label class="mt-0 ">
                              <span>{{list.discountName}}</span></label>
                      </div>
                      </div>
                    <div class="text-center mt-1">
                      <img class="card-img-top" [ngClass]="{'outofstock-img': list.isInventory && !list.isStockAvailable}" src="{{dataService.appConstant.IMAGE_URL}}{{list.imageFilePath}}"
                        loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                        <div class="out-of-stock-message" *ngIf="list.isInventory && !list.isStockAvailable">
                          Out of Stock
                        </div>
                    </div>
                    <div class="text-center">
                      <div class="text-color brandnamewidth" [tooltip]="list.itemName" placement="top" show-delay="500">
                        {{list.brandName}}
                      </div>
                      <div class="namewidth" [tooltip]="list.itemName" placement="top" show-delay="500">
                        <strong> {{list.itemName}}</strong>
                      </div>
                      <div class="price">
                        <span class="offer">{{currencySymbol}}
                          {{list.finalPrice}}</span>
                        <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                            <span>{{list.mrp}}</span></del></span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>

        </div>
      </section>
    </div>


      <!--Deal Of the Month 🔥-->
      <div *ngIf="recentlyView && recentlyView.cmsSectionBO && recentlyView.cmsSectionBO.cmsSectionContentBOList 
      && recentlyView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
         <section class="  antimated fadeDown" id="special-offer-sec"
           *ngFor="let pageSection of recentlyView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
           <h3 class="recent-product-title mt-3">{{ pageSection.title}}
           </h3>
           <div>
             <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
               <strong class="margin-left f-18 "> Similar Products</strong>
               <ng-container *ngFor="let prod of pageSection.itemList;let itemIndex=index">
                 <ng-template carouselSlide>
                   <div class="margin-top mx-2 ">
                     <div 
                       (click)="itemDetail(prod)" class="card-margin card-height" routerLink="/product-detail">
                       <div [ngClass]="{'margin-discount':prod.discountName == null}">
                         <div *ngIf="prod.discountName" class="badge percent-off p-1 text-danger">
                             <label class="mt-0 ">
                                 <span>{{prod.discountName}}</span></label>
                         </div>
                         </div>
                       <div class="text-center mt-1">
                         <img class="card-img-top" [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}" src="{{dataService.appConstant.IMAGE_URL}}{{prod.imageFilePath}}"
                           loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                           <div class="out-of-stock-message" *ngIf="prod.isInventory && !prod.isStockAvailable">
                            Out of Stock
                          </div>
                       </div>
                       <div class="text-center">
                         <div class="text-color brandnamewidth" [tooltip]="prod.itemName" placement="top" show-delay="500">
                           {{prod.brandName}}
                         </div>
                         <div class="namewidth" [tooltip]="prod.itemName" placement="top" show-delay="500">
                           <strong> {{prod.itemName}}</strong>
                         </div>
                         <div class="price">
                           <span class="offer">{{currencySymbol}}
                             {{prod.finalPrice}}</span>
                           <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                               <span>{{prod.mrp}}</span></del></span>
                         </div>
                       </div>
                     </div>
                   </div>
                 </ng-template>
               </ng-container>
             </owl-carousel-o>
   
           </div>
         </section>
       </div>

       <div *ngIf="frequentlyView && frequentlyView.cmsSectionBO && frequentlyView.cmsSectionBO.cmsSectionContentBOList 
       && frequentlyView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
          <section class="  antimated fadeDown" id="special-offer-sec"
            *ngFor="let pageSection of frequentlyView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
            <h3 class="recent-product-title mt-3">{{ pageSection.title}}
            </h3>
            <div>
              <owl-carousel-o [options]="carouselOptions" class="custom-carousel">
                <strong class="margin-left f-18 "> Similar Products</strong>
                <ng-container *ngFor="let prod of pageSection.itemList;let itemIndex=index">
                  <ng-template carouselSlide>
                    <div class="margin-top mx-2 ">
                      <div 
                        (click)="itemDetail(prod)" class="card-margin card-height" routerLink="/product-detail">
                        <div [ngClass]="{'margin-discount':prod.discountName == null}">
                          <div *ngIf="prod.discountName" class="badge percent-off p-1 text-danger">
                              <label class="mt-0 ">
                                  <span>{{prod.discountName}}</span></label>
                          </div>
                          </div>
                        <div class="text-center mt-1">
                          <img class="card-img-top" [ngClass]="{'outofstock-img': prod.isInventory && !prod.isStockAvailable}" src="{{dataService.appConstant.IMAGE_URL}}{{prod.imageFilePath}}"
                            loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                            <div class="out-of-stock-message" *ngIf="prod.isInventory && !prod.isStockAvailable">
                              Out of Stock
                            </div>
                        </div>
                        <div class="text-center">
                          <div class="text-color brandnamewidth" [tooltip]="prod.itemName" placement="top" show-delay="500">
                            {{prod.brandName}}
                          </div>
                          <div class="namewidth" [tooltip]="prod.itemName" placement="top" show-delay="500">
                            <strong> {{prod.itemName}}</strong>
                          </div>
                          <div class="price">
                            <span class="offer">{{currencySymbol}}
                              {{prod.finalPrice}}</span>
                            <span class="text-color price-margin"><del><span>{{currencySymbol}}</span>
                                <span>{{prod.mrp}}</span></del></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
    
            </div>
          </section>
        </div>
   

    <!--Brands-->
    <div
      *ngIf="topBrandView && topBrandView.cmsSectionBO && topBrandView.cmsSectionBO.cmsSectionContentBOList && topBrandView.cmsSectionBO.cmsSectionContentBOList.length > 0">
      <section class="pt-5 pb-3 antimated fadeDown" id="special-offer-sec"
        *ngFor="let pageSection of topBrandView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
        <div class="recent-product-title" routerLink="/web/ag/shop-by-brand">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3>{{pageSection.title}}</h3>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-end">
              <a>View All</a>
            </div>
          </div>
        </div>
        <div>
          <div *ngIf="pageSection.brandList">
            <div class="sliderrow row">
              <div size="4" *ngFor='let brand of pageSection.brandList'
                class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 desktopView">
                <div class="brandcard1" routerLink="/web/ag/products"
                  [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
                  <div class="brandimgdiv">
                    <img
                      src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-300,h-300,cm-pad_resize/{{brand.filePath}}" o
                      alt="" class="brandimg_mob center" nError="this.src='assets/img/errorLogo.png'">
                  </div>
                </div>
              </div>
              <div size="4" *ngFor='let brand of pageSection.brandList'
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 changecolpad  mobileView">
                <div class="brandcard2" routerLink="/web/ag/products"
                  [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
                  <div class="brandimgdiv1">
                    <img src="{{dataService.appConstant.IMAGE_BASE_URL}}{{brand.filePath}}" o alt=""
                      class="brandimg_mob center" nError="this.src='assets/img/errorLogo.png'">
                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mobileView">
                <div class=" brandcard3">
                  <div class="brandimgdiv2" routerLink="/web/ag/shop-by-brand">
                    <img src="assets/img/shop_by_view_all-arrow.svg" alt=""
                      class="responsive center brandarrow brandimg_mob">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="text-end">
    
      <a class="whatsapp" href="https://api.whatsapp.com/send/?phone=%2B91{{this.orgnizationData.mobile}}&text&type=phone_number&app_absent=0"
          class="float" target="_blank">
          <i class="fab fa-whatsapp my-float"></i>
      </a>

  </div>
  </div>



</body>