import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { getHomePageLists, getPageId, getPageWithSeqNo } from "../actions/homepage.action";
import { map, tap } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { AppConstantsService } from "src/app/constants/app-constants.service";
import { CommanservicesService } from "src/app/services/commanservices.service";
// State Model
export class getHomePageStateModel {
    pageList: any;
    pageListLoaded: boolean
}

// Get page by id 
export class getHomePageByIdStateModel {
    pageByIdList: any;
    pageListByIdLoaded: boolean
}

// get home page with seq no

export class getHomePageWithSeqNoStateModel {
    pageWithSeqNoList: any;
    pageWithSeqNoLoaded: boolean
}

@State<getHomePageStateModel>({
    name: 'PageList',
    defaults: {
        pageList: [],
        pageListLoaded: false
    }
})

// get page by id
@State<getHomePageByIdStateModel>({
    name: 'PageListById',
    defaults: {
        pageByIdList: [],
        pageListByIdLoaded: false
    }
})

@State<getHomePageWithSeqNoStateModel>({
    name: 'PageWithSeqNo',
    defaults: {
        pageWithSeqNoList: [],
        pageWithSeqNoLoaded: false
    }
})


@Injectable()
export class HomePageState {
    data: any;
    dummyObj: any = {};
    public URL: any;
    public tab: any;
    constructor(private appConstants: AppConstantsService,
        public dataService: CommanservicesService,
        public route: ActivatedRoute,
        public common: CommanservicesService,
        public constant: AppConstantsService) {
    }

    // selecter has logic to select data
    @Selector()
    static getPageListData(state: getHomePageStateModel) {
        return state.pageList
    }

    // get loaded brand
    @Selector()
    static getPageListLoaded(state: getHomePageStateModel) {
        return state.pageListLoaded;
    }


    @Action(getHomePageLists)
    getHomePageLists({ getState, setState }: StateContext<getHomePageStateModel>) {
        this.dataService.sendMessage("showLoader")
        return this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS['ECOMM_PAGE_LIST']).pipe(tap(result => {
            this.data = result;
            this.dataService.sendMessage("hideLoader")
            const state = getState();
            setState({
                ...state,
                pageList: this.data.result,
                pageListLoaded: true
            });
        }));
    }



    // get page by id logic
    @Selector()
    static getPageListByIdData(state: getHomePageByIdStateModel) {
        return state.pageByIdList
    }
    @Selector()
    static getPageListByIdLoaded(state: getHomePageByIdStateModel) {
        return state.pageListByIdLoaded;
    }
    @Action(getPageId)
    getHomePageById({ getState, setState }: StateContext<getHomePageByIdStateModel>, { id }: getPageId) {
        this.dataService.sendMessage("showLoader")
        return this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['GET_SEQ_OF_PAGE'] + "/" + id).pipe(tap(result => {
            this.data = result;
            this.dataService.sendMessage("hideLoader")
            const state = getState();
            setState({
                ...state,
                pageByIdList: this.data.result,
                pageListByIdLoaded: true
            });
        }));
    }

    // home page with Seq No
    @Selector()
    static getPageWithSeqNoData(state: getHomePageWithSeqNoStateModel) {
        return state.pageWithSeqNoList
    }
    @Selector()
    static getPageWithSeqNoLoaded(state: getHomePageWithSeqNoStateModel) {
        return state.pageWithSeqNoLoaded;
    }
    @Action(getPageWithSeqNo)
    getHomePageWithSeqNo({ getState, setState }: StateContext<getHomePageWithSeqNoStateModel>, { payload }: getPageWithSeqNo) {
        this.dataService.sendMessage("showLoader");
        return this.dataService.openPostRequest(payload, this.dataService.appConstant.SERVER_URLS['GET_PAGE_BY_SEQ']).pipe(tap(result => {
            this.data = result;
            if (this.data.result) {
                this.dataService.sendMessage("hideMoreLoader")
            }
            this.dataService.sendMessage("hideLoader")
            const state = getState();
            setState({
                ...state,
                pageWithSeqNoList: this.data.result,
                pageWithSeqNoLoaded: true
            });
            this.dataService.sendMessage("hideMoreLoader")
        }));
    }
}