import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthService } from '../services/auth.service';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AppConstantsService } from '../constants/app-constants.service';
// const TOKEN_HEADER_KEY = 'Authorization';  // for Spring Boot back-end
const TOKEN_HEADER_KEY = 's_a_t';    // for Node.js Express back-end
export let browserRefresh = false;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private tokenService: TokenStorageService, private authService: AuthService, public appConstant: AppConstantsService,) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        let authReq = req;
        const token = this.tokenService.getToken();
        if (token != null) {
            
            authReq = this.addTokenHeader(req, token);
        }
        return next.handle(authReq).pipe(catchError((error: any) => {
            if (error instanceof HttpErrorResponse && (!authReq.url.includes('auth/signin') &&  (error.status === 412 
                || error.status === 2000 || error.status === 2001)) || !browserRefresh) {
                return this.handle401Error(authReq, next);
            }
            return throwError(error);
        }));
    }
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {

            
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const token = this.tokenService.getRefreshToken();
            if (token){
                return this.authService.refreshToken(token).pipe(
                    switchMap((token: any) => {
                        if (token.status === 200) {
                            if (token.result) {
                                this.isRefreshing = false;
                                this.tokenService.saveToken(token.result.accessToken);
                                this.tokenService.saveRefreshToken(token.result.refreshToken);
                                this.refreshTokenSubject.next(token.result.accessToken);

                                return next.handle(this.addTokenHeader(request, token.result.accessToken));
                            } else {
                                this.isRefreshing = false;
                                this.tokenService.logoutWithMessage();
                            }
                        }
                        else if (token.status === 401) {
                            this.isRefreshing = false;
                            this.tokenService.logoutWithMessage();
                        }
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;
                        // this.tokenService.signOut();
                        return throwError(err);
                    })
                );
            }
                
        }
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }
    private addTokenHeader(request: HttpRequest<any>, token: string) {
        /* for Spring Boot back-end */
        // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        /* for Node.js Express back-end */
        var user = JSON.parse(localStorage.getItem('userInfo'));
        let orgId = JSON.parse(localStorage.getItem('User_Org'));
        let stringOrgId = String(orgId.organizationProfileId);
        if (user) {
            return request.clone({
                headers: new HttpHeaders({ 'timezone': 'Asia/Calcutta', 'Content-Type': 'application/json', 'organizationId': stringOrgId, 'tenant_name': this.appConstant.TENANT_NAME, 's_a_t': btoa(token + ":" + user.userUniqueId + ":" + user.deviceUniqueId) })
            })
        } else {
            return request.clone({
                headers: new HttpHeaders({ 'timezone': 'Asia/Calcutta', 'Content-Type': 'application/json', 'organizationId': stringOrgId, 'tenant_name': this.appConstant.TENANT_NAME, 's_a_t': token })
            })
        }
    }
}
export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];