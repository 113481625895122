import { NgZone } from '@angular/core';

// import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { environment } from '../environments/environment';


export function AngularFireAuthProject1(platformId: Object, zone: NgZone) {
    return new AngularFireAuth(environment.akashmart, 'firebase-project1-auth', platformId, zone);
  }

export function AngularFireAuthProject2(platformId: Object, zone: NgZone) {
  return new AngularFireAuth(environment.reliablemart, 'firebase-project2-auth', platformId, zone);
}

export function AngularFireAuthProject3(platformId: Object, zone: NgZone) {
  return new AngularFireAuth(environment.shakewellnutrition, 'firebase-project2-auth', platformId, zone);
}